import {
  AppstoreFilled,
  ClockCircleFilled,
  MessageOutlined,
  ProjectOutlined,
  RetweetOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import {
  SelectShumokuModal,
  TSubmitShumokuModalValue,
} from "components/SelectShumokuModal/SelectShumokuModal";
import {
  DEFAULT_WEIGHT,
  NUMBER_OF_DECIMAL_PLACES,
  TYPE_SHUMOKU_TIMES,
  UNIT_OF_WEIGHT,
  WEIGHT_FIELD_DISPLAY,
} from "constants/common";
import images from "constants/images";
import MESSAGES from "constants/messages";
import { BodyIcon, PlaySquare } from "constants/svgs";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { useAppLoading, useAppModal } from "contexts";
import { programActions } from "features/program/programSlice";
import { replaceErrorImage } from "helpers";
import { showErrorNotification } from "helpers/notification";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  TMemberShumokuHistoryResponse,
  TShumoku,
  TSubmitMemberShumokuModalValue,
} from "model/program";
import { FC, Fragment, ReactNode, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ShumokuItemStyled } from "./ShumokuItem.styled";
import { ShumokuVideo } from "./components";
import { UpdateMemberShumokuModal } from "components/UpdateMemberShumokuModal/UpdateMemberShumokuModal";
import {
  ModalContentStyled,
  SelectShumokuModalStyled,
} from "components/SelectShumokuModal/SelectShumokuModal.style";
import programApi from "features/program/programApi";

type TProps = {
  data: TShumoku;
  orderNumber?: string;
  type?: string;
  isUpdate?: boolean;
};

const TEXT_TRANSLATION = {
  UPDATE: "編集",
  DATE_COLUMN: "日付",
  WEIGHT_COLUMN: `${WEIGHT_FIELD_DISPLAY}`,
  SHOW_HISTORY: "履歴",
};

function ShumokuInfoItem({
  type,
  icon,
  value,
  isDisplayHistory,
  memberShumokuId,
  isUpdate,
  memberShumokuHistories,
}: {
  type?: string;
  icon: ReactNode;
  value?: string | number | null;
  isDisplayHistory?: boolean | null;
  memberShumokuId?: string | undefined;
  isUpdate?: boolean | null;
  memberShumokuHistories?: TMemberShumokuHistoryResponse[] | null;
}) {
  if (!value) return <Fragment />;

  const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false);
  const [historyData, setHistoryData] = useState<
    TMemberShumokuHistoryResponse[]
  >([]);

  const { startLoading, stopLoading } = useAppLoading();
  const [loading, setLoading] = useState(false);

  const showModalHistory = async () => {
    setIsModalHistoryOpen(true);
    setLoading(true);
    try {
      startLoading();
      if (isUpdate) {
        setHistoryData(
          memberShumokuHistories as TMemberShumokuHistoryResponse[]
        );
      } else {
        const response = await programApi.getMemberShumokuHistory(
          memberShumokuId as string
        );
        setHistoryData(response.data);
      }
    } catch (error) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalHistoryOpen(false);
    setHistoryData([]);
  };

  const columnsTableWeightHistory = [
    {
      title: TEXT_TRANSLATION.DATE_COLUMN,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) =>
        new Intl.DateTimeFormat("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(date)),
      align: "center" as const,
    },
    {
      title: TEXT_TRANSLATION.WEIGHT_COLUMN,
      dataIndex: "weightLevel",
      key: "weightLevel",
      align: "center" as const,
      render: (weightLevel: number) =>
        weightLevel.toFixed(NUMBER_OF_DECIMAL_PLACES),
    },
  ];

  return (
    <div className="shumoku-info-item">
      <span className="icon">{icon}</span>
      <span className="content">{value}</span>
      {type === "weightLevel" && isDisplayHistory ? (
        <span className="history" onClick={showModalHistory}>
          {TEXT_TRANSLATION.SHOW_HISTORY}
        </span>
      ) : (
        <></>
      )}

      <SelectShumokuModalStyled>
        <Modal
          title={TEXT_TRANSLATION.SHOW_HISTORY}
          centered
          open={isModalHistoryOpen}
          onCancel={handleCancel}
          maskClosable={false}
          footer={null}
        >
          <ModalContentStyled style={{ borderBottom: "0px" }}>
            <Table
              rowKey={(record) => record.id as string}
              columns={columnsTableWeightHistory}
              dataSource={historyData}
              pagination={false}
              locale={{ emptyText: "" }}
              loading={loading}
            />
          </ModalContentStyled>
        </Modal>
      </SelectShumokuModalStyled>
    </div>
  );
}

const ShumokuItem: FC<TProps> = (props) => {
  const { data, orderNumber, isUpdate } = props;
  const [openVideo, setOpenVideo] = useState(false);

  const dispatch = useAppDispatch();
  const { programDetail } = useAppSelector((state) => state.program);
  const shumokuIndex: number = Number(orderNumber) - 1;
  const shumokuExtensions = useMemo(() => {
    return programDetail?.shumokuExtensions.map((item: TShumoku) => {
      return item;
    });
  }, [programDetail?.shumokuExtensions]);
  const { showModalSuccess } = useAppModal();
  const [searchParams, _setSearchParams] = useSearchParams();
  const { startLoading, stopLoading } = useAppLoading();

  const handleVideoClick = () => {
    setOpenVideo(true);
  };
  const programMemberId = searchParams.get("programId");
  const memberId = searchParams.get("memberId");

  const handleUpdateShumoku = (value: TSubmitShumokuModalValue) => {
    try {
      startLoading();

      const shumokuExtensionList = [
        ...(shumokuExtensions as unknown as TShumoku[]).slice(0, shumokuIndex),
        value as any as TShumoku,
        ...(shumokuExtensions as unknown as TShumoku[]).slice(shumokuIndex + 1),
      ];

      dispatch(
        programActions.updateProgram({
          data: {
            programMemberId: programMemberId as string,
            extensions: JSON.stringify(shumokuExtensionList),
          },
          onSuccess: () => {
            showModalSuccess({
              title: MESSAGES["SCR-W16-MSG-002"],
              okText: GLOBAL_TRANSLATE.CLOSE,
            });
            dispatch(
              programActions.getProgramDetail({
                data: {
                  programId: programMemberId as string,
                  memberId: memberId as string,
                },
              })
            );
          },
        })
      );
    } catch (error: any) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleUpdateMemberShumoku = (value: TSubmitMemberShumokuModalValue) => {
    try {
      startLoading();
      dispatch(
        programActions.updateMemberShumoku({
          data: {
            memberId: memberId as string,
            shumokuMasterId: value.shumokuMasterId as string,
            weightLevel: value.weightLevel,
            note: value.note,
          },
          onSuccess: () => {
            showModalSuccess({
              title: MESSAGES["SCR-W16-MSG-002"],
              okText: GLOBAL_TRANSLATE.CLOSE,
            });
            dispatch(
              programActions.getProgramDetail({
                data: {
                  programId: programMemberId as string,
                  memberId: memberId as string,
                },
              })
            );
          },
        })
      );
    } catch (error: any) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const isTimeShumokuType =
    data?.shumokuTimes.slice(-1) === TYPE_SHUMOKU_TIMES.TIMES;

  const shumokuInfoList = [
    {
      key: "location",
      icon: <BodyIcon />,
      value: data?.location,
    },
    {
      key: "shumokuTimes",
      icon: isTimeShumokuType ? <RetweetOutlined /> : <ClockCircleFilled />,
      value: data?.shumokuTimes,
    },
    {
      key: "shumokuSetNumber",
      icon: <AppstoreFilled />,
      value: data?.shumokuSetNumber,
    },
    {
      key: "tempo",
      icon: <ProjectOutlined />,
      value: data?.tempo,
    },
  ];

  const memberShumokuInfoList = [
    {
      key: "weightLevel",
      icon: <TrophyOutlined />,
      value: data?.isDisplayWeightLevel
        ? data?.memberShumoku?.weightLevel
          ? `${Number(data.memberShumoku.weightLevel).toFixed(
              NUMBER_OF_DECIMAL_PLACES
            )}${UNIT_OF_WEIGHT}`
          : `${DEFAULT_WEIGHT}`
        : null,
    },
    {
      key: "note",
      icon: <MessageOutlined />,
      value: data?.memberShumoku?.note,
    },
  ];

  return (
    <Fragment>
      <ShumokuItemStyled>
        <div className="video-index">
          <span className="order-number">{orderNumber}</span>
        </div>

        <div className="program-title">{data?.shumokuName}</div>

        <div className="program-body">
          <div className="video-item" onClick={handleVideoClick}>
            <img
              src={data?.thumbnail || images.ThumbnailImage}
              onError={replaceErrorImage}
              alt={data?.shumokuName}
              loading="lazy"
            />

            <div className="thumbnail-overlay">
              <PlaySquare />
            </div>
          </div>

          <div className="program-index">
            <div className="shumoku-info">
              {shumokuInfoList.map((item) => (
                <ShumokuInfoItem
                  key={item.key}
                  icon={item.icon}
                  value={item.value}
                />
              ))}
            </div>
            <div className="member-shumoku-info">
              {memberShumokuInfoList.map((item) => (
                <ShumokuInfoItem
                  key={item.key}
                  type={item.key}
                  icon={item.icon}
                  value={item.value}
                  isDisplayHistory={
                    data?.memberShumoku?.weightLevel ? true : false
                  }
                  memberShumokuId={data?.memberShumoku?.id}
                  isUpdate={isUpdate}
                  memberShumokuHistories={data?.memberShumokuHistories}
                />
              ))}
            </div>
          </div>
        </div>

        {isUpdate ? (
          <SelectShumokuModal
            onSubmit={handleUpdateShumoku}
            initialShumoku={data}
          >
            <Button htmlType="button" className="btn-update-shumoku">
              {TEXT_TRANSLATION.UPDATE}
              {""}
            </Button>
          </SelectShumokuModal>
        ) : (
          <UpdateMemberShumokuModal
            onSubmit={handleUpdateMemberShumoku}
            initialShumoku={data}
          >
            <Button htmlType="button" className="btn-update-shumoku">
              {TEXT_TRANSLATION.UPDATE}
              {""}
            </Button>
          </UpdateMemberShumokuModal>
        )}
      </ShumokuItemStyled>

      <ShumokuVideo
        open={openVideo}
        handleOk={() => setOpenVideo(true)}
        handleCancel={() => setOpenVideo(false)}
        video={data}
      />
    </Fragment>
  );
};

export { ShumokuItem };
