import { TBodyIndexChartItem } from "components";
import { MIN_CHECK_IN_TIMES_FOR_NEXT_PHASE } from "constants/common";
import MESSAGES from "constants/messages";
import { useAppLoading, useAppLocation } from "contexts";
import dayjs from "dayjs";
import memberApi, {
  EGetMemberError,
  TCheckInParamsReq,
} from "features/member/memberApi";
import { dayjsLocalFromUTC, getISOStringDate } from "helpers";
import { showErrorNotification } from "helpers/notification";
import {
  TBodyIndexInfo,
  THistoryCheckIn,
  TMemberDetail,
  TTrainingProgram,
} from "model/member";
import { ESurveyPhase } from "model/survey";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HomeRoute,
  MemberConfirmationRoute,
  MemberListRoute,
  SurveyDetailRoute,
  TMemberUrlParams,
  TrainingProgramDetailRoute,
  TrainingProgramRoute,
} from "routers/router";
import { TResponse } from "types/service";
import { EMemberActionStatus } from "../components/MemberStatistic/components";
import { programActions } from "features/program/programSlice";
import { useAppDispatch } from "hooks";

type TBodyIndexChartItemExtend = TBodyIndexChartItem & TBodyIndexInfo;

type TMemberDetailPageContext = {
  loading: boolean;
  hasError: boolean;
  memberId: string;
  memberDetail?: TMemberDetail;
  historyCheckIn: THistoryCheckIn[];
  bodyIndex?: TBodyIndexInfo;
  totalCheckInTimes: number;
  memberAction?: EMemberActionStatus;
  handleUpdateBodyIndex: (values?: TBodyIndexInfo) => void;
  handleCheckInSuccess?: (values: TCheckInParamsReq) => void;
  handleBackPage: () => void;
  navigateToCreateProgramPage: () => void;
  navigateToProgramDetailPage: (program: TTrainingProgram) => void;
  handleViewSurvey: (phaseNumber: number) => void;
  bodyIndexHistory: TBodyIndexChartItemExtend[];
  phaseNumber: number;
};

const initialModalValues: TMemberDetailPageContext = {
  totalCheckInTimes: 0,
  historyCheckIn: [],
  bodyIndexHistory: [],
  memberId: "",
  phaseNumber: 0,
  loading: true,
  hasError: false,
  handleUpdateBodyIndex: () => {
    //
  },
  handleBackPage: () => {
    //
  },
  navigateToCreateProgramPage: () => {
    //
  },
  navigateToProgramDetailPage: () => {
    //
  },
  handleViewSurvey: () => {
    //
  },
};

function getLatestBodyIndexHistory(
  history: TBodyIndexInfo[],
  limit = 3
): TBodyIndexChartItemExtend[] {
  const _bodyIndexHis: TBodyIndexChartItemExtend[] = [];

  let i = history.length - 1;
  while (i >= 0 && _bodyIndexHis.length < limit) {
    const formattedInfo: TBodyIndexChartItemExtend = {
      ...history[i],
      date: dayjsLocalFromUTC(history[i].createdAt).format("MM月DD日"),
      value: 0,
    };
    _bodyIndexHis.unshift(formattedInfo);
    i--;
  }

  return _bodyIndexHis;
}

const MemberDetailPageContext =
  createContext<TMemberDetailPageContext>(initialModalValues);

const useMemberDetailPageContext = () => useContext(MemberDetailPageContext);

function MemberDetailPageContextProvider(props: PropsWithChildren) {
  const { children } = props;

  const [memberDetail, setMemberDetail] = useState<TMemberDetail>();
  const [historyCheckIn, setHistoryCheckIn] = useState<THistoryCheckIn[]>([]);
  const [bodyIndex, setBodyIndex] = useState<TBodyIndexInfo>();
  const [totalCheckInTimes, setTotalCheckInTimes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [memberAction, setMemberAction] = useState<EMemberActionStatus>();
  const [bodyIndexHistory, setBodyIndexHistory] = useState<
    TBodyIndexChartItemExtend[]
  >([]);
  const [phase, setPhase] = useState<number>(0);
  const { memberId = "" } = useParams<TMemberUrlParams>();
  const { startLoading, stopLoading } = useAppLoading();
  const { backToPrevPage } = useAppLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const updateBodyIndexState = (data: TBodyIndexInfo) => {
    setBodyIndex({
      weight: data.weight,
      height: data.height,
      bodyFat: data.bodyFat,
      minBloodPressure: data.minBloodPressure,
      maxBloodPressure: data.maxBloodPressure,
      heartbeat: data.heartbeat,
    });

    // Update body index in member detail state
    const { bodyIndexHistories } = memberDetail || {};

    if (!memberDetail || !bodyIndexHistories) return;

    const todayIndexInHistory = bodyIndexHistories.findIndex(
      (his: TBodyIndexInfo) =>
        dayjsLocalFromUTC(his.createdAt).isSame(dayjs(), "date")
    );

    if (todayIndexInHistory !== -1) {
      const newBodyIndexHistory = [
        ...bodyIndexHistories.slice(0, todayIndexInHistory),
        { ...bodyIndexHistories[todayIndexInHistory], ...data },
        ...bodyIndexHistories.slice(todayIndexInHistory + 1),
      ];
      setMemberDetail({
        ...memberDetail,
        bodyIndexHistories: newBodyIndexHistory,
      });
    } else {
      setMemberDetail({
        ...memberDetail,
        bodyIndexHistories: [
          ...memberDetail.bodyIndexHistories,
          { ...data, createdAt: getISOStringDate() },
        ],
      });
    }
  };

  const getMemberDetailInfo = async (id: string) => {
    const { data } = await memberApi.getMemberById(id);

    if (data) {
      setMemberDetail(data);
      updateBodyIndexState(data);

      const _totalCheckInTimes = data.programs?.reduce(
        (total, prog) => total + prog.numOfTrainingSession,
        0
      );
      setTotalCheckInTimes(_totalCheckInTimes || 0);

      if (data.latestCheckIn) {
        await getHistoryCheckIn(id);
      }

      return true;
    }

    return false;
  };

  const getHistoryCheckIn = async (id: string) => {
    try {
      const { data } = await memberApi.getHistoryCheckInById(id);

      if (data) {
        setHistoryCheckIn(data.history);
      } else {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    }
  };

  const getInitialData = async (id: string) => {
    try {
      setLoading(true);
      setHasError(false);
      const isGetDetail = await getMemberDetailInfo(id);
      if (!isGetDetail) {
        setHasError(true);
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e: any) {
      const errorCode = (e?.data as TResponse)?.errors?.[0]?.code;

      if (errorCode === EGetMemberError.FORBIDDEN_STORE) {
        showErrorNotification(MESSAGES["COM-MSG-004"]);
      } else {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }

      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBodyIndex = async (values?: TBodyIndexInfo) => {
    if (!values) return;

    try {
      startLoading();
      const { data } = await memberApi.addBodyIndexRecord(memberId, values);
      if (data) {
        updateBodyIndexState(data);
      } else {
        showErrorNotification(MESSAGES["COM-MSG-002"]);
      }
    } catch (e) {
      showErrorNotification(MESSAGES["COM-MSG-002"]);
    } finally {
      stopLoading();
    }
  };

  const handleCheckInSuccess = (values: TCheckInParamsReq) => {
    setTotalCheckInTimes((prev) => prev + 1);
    setHistoryCheckIn((prev) => [
      ...prev,
      { checkedInDate: values.checkInDate },
    ]);

    // Update last check in date
    setMemberDetail((prev) => {
      if (!prev) return undefined;

      const isCheckInOldDate = dayjsLocalFromUTC(values.checkInDate).isBefore(
        dayjsLocalFromUTC(prev.latestCheckIn)
      );

      if (isCheckInOldDate) return prev;

      return {
        ...prev,
        latestCheckIn: getISOStringDate(values.checkInDate),
      };
    });

    // Update last check in date in program list
    const programs = memberDetail?.programs || [];
    const programIndex = programs.findIndex(
      (p) => p.programMaster.id === values.programId
    );
    if (programIndex !== -1) {
      const updatedPrograms: TTrainingProgram[] = [
        ...programs.slice(0, programIndex),
        {
          ...programs[programIndex],
          latestCheckIn: getISOStringDate(values.checkInDate),
        },
        ...programs.slice(programIndex + 1),
      ];
      setMemberDetail((prev) => {
        if (!prev) return undefined;
        return {
          ...prev,
          programs: updatedPrograms,
        };
      });
    }
  };

  const handleBackPage = () => {
    backToPrevPage([
      HomeRoute.path,
      MemberListRoute.path,
      MemberConfirmationRoute.path,
    ]);
  };

  const handleViewSurvey = (phaseNumber: number) => {
    const surveyDetailPath = SurveyDetailRoute.genPath({
      memberId,
      phaseNumber,
      step: 0,
    });

    navigate(surveyDetailPath);
  };

  const navigateToCreateProgramPage = () => {
    dispatch(
      programActions.setProgramSurvey({
        data: {
          programMenu: 0,
          frequency: 0,
          duration: 0,
          phaseNumber: 0,
          hasOther: null,
          questionId: "",
        },
      })
    );
    dispatch(programActions.setViewSurvey({ data: false }));
    dispatch(programActions.setIsKeepValue({ data: false }));
    navigate(
      TrainingProgramRoute.genPath({ memberId, phaseNumber: phase as number })
    );
  };

  const navigateToProgramDetailPage = (program: TTrainingProgram) => {
    const programId = program.id;
    dispatch(programActions.setViewSurvey({ data: false }));
    dispatch(programActions.setIsKeepValue({ data: false }));
    navigate(TrainingProgramDetailRoute.genPath({ memberId, programId }));
  };

  // Effect
  useEffect(() => {
    getInitialData(memberId);
  }, [memberId]);

  // Update member action
  useEffect(() => {
    const hasFilledSurveyNextPhase = memberDetail
      ? !!memberDetail.phaseResponses.find(
          (phase) => phase.phaseNumber === ESurveyPhase.GYM_PHASE_2
        )
      : false;
    const hasCreatedNextPhaseProgram = memberDetail?.programs
      ? memberDetail.programs.length >= 2
      : false;
    const hasProgram = memberDetail?.programs
      ? memberDetail.programs.length > 0
      : false;

    if (!hasProgram) {
      setMemberAction(EMemberActionStatus.NEED_CREATE_PROGRAM);
      setPhase(ESurveyPhase.GYM_PHASE_1);
    } else if (
      totalCheckInTimes >= MIN_CHECK_IN_TIMES_FOR_NEXT_PHASE &&
      !hasFilledSurveyNextPhase
    ) {
      setMemberAction(EMemberActionStatus.NEED_DO_SURVEY_PHASE_2);
    } else if (
      totalCheckInTimes >= MIN_CHECK_IN_TIMES_FOR_NEXT_PHASE &&
      !hasCreatedNextPhaseProgram
    ) {
      setMemberAction(EMemberActionStatus.NEED_CREATE_PROGRAM_PHASE_2);
      setPhase(ESurveyPhase.GYM_PHASE_2);
    } else {
      setMemberAction(undefined);
    }
  }, [totalCheckInTimes, memberDetail]);

  // Update body index history
  useEffect(() => {
    if (memberDetail) {
      const sortedHistory = memberDetail.bodyIndexHistories.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          if (a.createdAt > b.createdAt) return 1;
          if (a.createdAt < b.createdAt) return -1;
          return 0;
        }
        return 0;
      });

      const _bodyIndexHis = getLatestBodyIndexHistory(sortedHistory);

      setBodyIndexHistory(_bodyIndexHis);
    }
  }, [memberDetail]);

  return (
    <MemberDetailPageContext.Provider
      value={{
        historyCheckIn,
        totalCheckInTimes,
        bodyIndex,
        memberDetail,
        memberId,
        loading,
        hasError,
        memberAction,
        phaseNumber: phase,
        handleBackPage,
        bodyIndexHistory,
        handleUpdateBodyIndex,
        handleCheckInSuccess,
        handleViewSurvey,
        navigateToCreateProgramPage,
        navigateToProgramDetailPage,
      }}
    >
      {children}
    </MemberDetailPageContext.Provider>
  );
}

export { MemberDetailPageContextProvider, useMemberDetailPageContext };
