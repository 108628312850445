import { Pie, PieConfig } from "@ant-design/plots";
import {
  LegendItemStyled,
  LegendStyled,
  SurveyStatisticDonutChartWrapperStyled,
} from "./SurveyStatisticDonutChart.styled";
import { numberWithCommas } from "helpers";
import { TStatisticalSurveyByQuestion } from "features/statistic/statisticApi";
import { memo } from "react";
import { NUMBER_OF_DECIMAL_PLACES } from "constants/common";

function SurveyStatisticDonutChartInternal(
  props: TStatisticalSurveyByQuestion
) {
  const colors = [
    "#99CAF8",
    "#FA541C",
    "#FFC62D",
    "#85BF64",
    "#27BEB8",
    "#2D9596",
    "#9AD0C2",
    "#F1FADA",
  ];

  const filteredColorForChartConfigs = props.answers.map((answer, index) => ({
    answerName: answer.answerName,
    color: colors[index % colors.length],
  }));

  const colorForLegendConfigs = colors.map((item) => item);

  const config: PieConfig = {
    data: props.answers,
    height: 169,
    width: 169,
    angleField: "quantityAnswer",
    colorField: "answerName", // Set to "answerName" to distinguish different answers
    radius: 1,
    innerRadius: 0.6,
    label: false,
    statistic: {
      title: false,
      content: {
        customHtml: () => {
          const displayValue = numberWithCommas(props.totalAnswer);
          // Auto wrap line when `displayValue` has more than 3 digits
          return `<div class="member-statistic-content ${
            displayValue.length >= 3 ? "wrap-line" : ""
          }">
            <span class="total-member" style="padding: 0">${displayValue}</span><span class="unit" style="display: inline">人</span>
          </div>`;
        },
      },
    },
    tooltip: false,
    color: filteredColorForChartConfigs.map((item) => item.color),
    legend: false,
    animation: false,
  };

  return (
    <SurveyStatisticDonutChartWrapperStyled>
      <h2 className="chart-title">{props.questionName}</h2>

      <div className="chart-container">
        <Pie {...config} />

        <LegendStyled>
          {props.answers.map((item, index) => {
            const percent = item.percentAnswer.toFixed(
              NUMBER_OF_DECIMAL_PLACES
            );
            return (
              <LegendItemStyled
                key={index}
                color={colorForLegendConfigs[index]}
              >
                <p className="legend-item-title">{item.answerName}</p>
                <p>
                  <span className="legend-item-vale">
                    {item.quantityAnswer}
                  </span>
                  <span className="legend-item-percent">{percent}%</span>
                </p>
              </LegendItemStyled>
            );
          })}
        </LegendStyled>
      </div>
    </SurveyStatisticDonutChartWrapperStyled>
  );
}

const SurveyStatisticDonutChart = memo(SurveyStatisticDonutChartInternal);

export { SurveyStatisticDonutChart };
