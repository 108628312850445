import {
  AppstoreFilled,
  ClockCircleFilled,
  MessageOutlined,
  ProjectOutlined,
  RetweetOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import {
  DEFAULT_WEIGHT,
  EMPTY_STRING,
  NOTE_FIELD,
  NUMBER_OF_DECIMAL_PLACES,
  TYPE_SHUMOKU_TIMES,
  UNIT_OF_WEIGHT,
  WEIGHT_FIELD,
} from "constants/common";
import { CloseCircleFillWhite, pictureSvgs } from "constants/svgs";
import { GLOBAL_TRANSLATE } from "constants/translate";
import { TShumoku } from "model/program";
import { FC } from "react";
import {
  VideoModalStyled,
  VideoModalWrapperStyled,
} from "./ShumokuVideo.styled";

type TProps = {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  video: TShumoku;
  isShowParams?: boolean;
};

const ShumokuVideo: FC<TProps> = ({
  open,
  isShowParams = true,
  handleCancel,
  handleOk,
  video,
}) => {
  const EXERCISE_PARAMS = [
    {
      id: "1",
      icon:
        video?.shumokuTimes &&
        video?.shumokuTimes.slice(-1) === TYPE_SHUMOKU_TIMES.TIMES ? (
          <RetweetOutlined />
        ) : (
          <ClockCircleFilled />
        ),
      label:
        video?.shumokuTimes &&
        video?.shumokuTimes.slice(-1) === TYPE_SHUMOKU_TIMES.TIMES
          ? GLOBAL_TRANSLATE.NUMBER_OF_REPS
          : GLOBAL_TRANSLATE.DURATION,
      content: video?.shumokuTimes || EMPTY_STRING,
    },
    {
      id: "2",
      icon: <AppstoreFilled />,
      label: "セット",
      content: video?.shumokuSetNumber || EMPTY_STRING,
    },
    ...(video?.tempo
      ? [
          {
            id: "3",
            icon: <ProjectOutlined />,
            label: "テンポ",
            content: video?.tempo,
          },
        ]
      : []),
    ...(video?.isDisplayWeightLevel && video?.memberShumoku?.weightLevel
      ? [
          {
            id: "4",
            icon: <TrophyOutlined />,
            label: `${WEIGHT_FIELD}`,
            content: video?.memberShumoku?.weightLevel
              ? `${Number(video.memberShumoku.weightLevel).toFixed(
                  NUMBER_OF_DECIMAL_PLACES
                )}${UNIT_OF_WEIGHT}`
              : `${DEFAULT_WEIGHT}`,
          },
        ]
      : []),
    ...(video?.memberShumoku?.note
      ? [
          {
            id: "5",
            icon: <MessageOutlined />,
            label: `${NOTE_FIELD}`,
            content: video?.memberShumoku?.note,
          },
        ]
      : []),
  ];

  return (
    <VideoModalStyled>
      <Modal
        centered
        closable={false}
        maskClosable={false}
        open={open}
        maskStyle={{ background: "rgba(23, 24, 29, 0.9)" }}
        wrapClassName="video-modal-wrapper"
        width={621}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <VideoModalWrapperStyled>
          <video
            className="video"
            src={video?.videoUrl}
            controls
            poster={
              !video?.videoUrl ? pictureSvgs.ImagePlaceholderUrl : undefined
            }
          >
            Your browser does not support the video tag.
          </video>

          <div>
            <p className="title">{video?.shumokuName}</p>
            {isShowParams && (
              <div className="exercise-index">
                {video?.memberShumoku?.note ? (
                  <>
                    <div className="exercise-params">
                      {EXERCISE_PARAMS.slice(0, -1).map((item) => {
                        return (
                          <div key={item?.id} className="exercise-index-item">
                            <div className="icon">{item?.icon}</div>
                            <div className="label">{item?.label}</div>
                            <div className="content">{item?.content}</div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="exercise-note-params">
                      {EXERCISE_PARAMS.slice(-1).map((item) => {
                        return (
                          <div key={item?.id} className="exercise-index-item">
                            <div className="icon">{item?.icon}</div>
                            <div className="label">{item?.label}</div>
                            <div className="content-note">{item?.content}</div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="exercise-params">
                      {EXERCISE_PARAMS.map((item) => {
                        return (
                          <div key={item?.id} className="exercise-index-item">
                            <div className="icon">{item?.icon}</div>
                            <div className="label">{item?.label}</div>
                            <div className="content">{item?.content}</div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div key="back" onClick={handleCancel} className="modal-footer">
            <CloseCircleFillWhite />
          </div>
        </VideoModalWrapperStyled>
      </Modal>
    </VideoModalStyled>
  );
};

export { ShumokuVideo };
